<template>
  <div>
    <div class="container-fluid">
      <div class="container" style="padding-bottom: 100px;">
        <div class="col-lg-8 col-12" style="padding: 0; margin: 0 auto;">
          <h1 class="page-title" style="margin-bottom: 25px;">Terms of Use</h1>
          <p class="gray-text" style="margin-top: 45px;">Aslice Terms of Use</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Last updated: June 4, 2021
          </p>
          <p class="gray-text">
            Welcome to our terms of use page. We’re pretty convinced that only
            lawyers read these things, so before we dive into the lengthy boring
            stuff, we thought it would be useful to highlight the top seven things
            that might actually be important for non-lawyers to read, and word it
            in non-legally-binding, everyday English. (For more of this sort of
            info, please check out our
            <span
              ><a target="_blank" class="green-link" href="/faq"
                >FAQ Section</a
              ></span
            >.)
          </p>
          <p class="section-title-purple">For Artists / Producers:</p>
          <p class="gray-text">
            Is my music available to listen to or download on Aslice? Absolutely
            not. There is no way to upload audio files, the site has no audio
            players and it contains no audio to download. Aslice only allows
            uploads of DJ playlists, DJ history files and song information. We are
            not a retail digital downloads site, a streaming platform or a
            filesharing service. How much do I get paid when a DJ plays my song?
            It varies. The amount you get paid every time one of your songs is
            played is determined by how much of their fee the DJ “shares” when
            they upload their playlist. While DJs can share any amount, we
            recommend 5%, as we think it’s large enough to create substantive
            change in our industry, but small enough that the impact on individual
            DJs will be negligible.
          </p>
          <p class="section-title-purple">For DJs:</p>
          <p class="gray-text" style="margin-bottom: 15px">
            Do I have to register with Aslice and share my DJ fees with producers?
            No. No DJ is obligated to register with the site or share their fees
            with the people whose music they play. Aslice is completely voluntary.
            However, the question we ask in return is “should you?” Aslice is
            built on a notion of promoting fairness, and currently, our music
            ecosystem works in a way that unfairly skews the financial
            relationship between DJs and producers. Our vision for a better
            culture involves DJs sharing a tiny bit of their pre-tax gig fees with
            producers, and because any amounts shared will be tax deductible, DJs
            won’t actually lose any money in the long run, but their contributions
            will nonetheless be enough to properly support producers’ role in the
            culture.
          </p>
          <p class="gray-text">
            What are the penalties if I don’t share money from a gig? Can’t people
            just cheat and game the system? Aslice is completely voluntary, so
            there are no penalties if you choose not to share. We also never ask
            DJs how much they earn, so we have no way to effectively “police”
            their donations—nor do we have any desire to do so. Given that, people
            can technically “cheat” by sharing infrequently or sharing low
            percentages of their fees, but rather than trying to stop that, we’ve
            instead focused our efforts on creating a system that’s affordable,
            easy to use and beneficial for everyone involved. As we developed
            Aslice and spoke with focus groups, we learned that sharing 5% was an
            amount that felt reasonable and fair as a starting point for DJs of
            all levels. Additionally, once DJs learned that sharing via Aslice was
            tax deductible, the vast majority found it easy to say yes and get on
            board, because participating would cost them virtually nothing while
            simultaneously contributing to the health of the greater music
            community.
          </p>
          <p class="gray-text color-purple" style="margin: 15px 0;">
            How much should I share? Why do you suggest 5%?
          </p>
          <p class="gray-text">
            Aslice is a voluntary service, and how much you decide to share is
            completely up to you. That can be a flat amount, more than 5% or less
            than that. What we’re most interested in is building a model that
            works for our community, and getting everyone to participate. We
            suggest sharing 5% because we’ve found it to be a sweet spot; it’s
            small enough that DJs can write off the expenditure as a taxable
            expense just like travel and music purchases—meaning it will cost them
            virtually nothing—but it’s also big enough to cumulatively create
            substantive change in our industry.
            <span
              ><a
                class="red-link"
                target="_blank"
                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&ved=2ahUKEwji3rCN7vPwAhWh4jgGHV_PDf4QFjAFegQIAxAF&url=https%3A%2F%2Fwww.internationalmusicsummit.com%2Fwp-content%2Fuploads%2F2019%2F05%2FIMS-Business-Report-2019-vFinal.pdf&usg=AOvVaw0pqdNbOXCrY1mDzMN1GH2y"
                >A 2019 report</a
              ></span
            >
            by the International Music Summit (IMS) indicated that DJs
            collectively earned $1.1 Billion dollars that year. If we can get our
            entire community on board with the concept of sharing 5%, then $55
            million will be paid out each year to producers—money they would have
            never received otherwise. We know that 100% participation is a lofty
            goal, but it’s one that’s worth chasing when the potential impact is
            so significant.
          </p>
          <p class="section-title-purple">For Fans:</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Is my personal information going to be re-sold to some random
            third-party advertiser? We will not sell your data. We are from the
            electronic music and DJ communities, and our mission is to create a
            fairer model where everyone can not only survive, but also thrive. To
            put it bluntly: we aren’t interested in participating in surveillance
            capitalism, and we respect your privacy.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            How can I support DJs and producers I like in the same way you allow
            DJs to? Right now, the best ways to support your favorite DJs and
            producers are through existing structures like buying their music and
            merchandise directly or on sites like Bandcamp, or subscribing to
            their Patreons and Twitch channels. We do see a future where fans can
            directly tip DJs and producers and have been thinking about this since
            our earliest days, but we are still working out a few kinks in terms
            of how we might approach this. Regardless, we hope to offer something
            in the near future, as it’s been a frequent question in our focus
            groups.
          </p>
          <p class="gray-text">
            From here on are the “real” terms of use that our lawyer has written.
            Be warned—it’s dense. Aslice (“Aslice”, “we”, “us” or “our”) provides
            Users (as defined below) with a web-based platform with a mobile
            application (collectively, the “Services”). Learn more about our
            Services at Aslice.com. Aslice, we, us or “our” includes any entity or
            person directly or indirectly owning a controlling interest in, owned
            by, or under common ownership control with Aslice (each an
            “Affiliate”)
          </p>
          <p class="page-title" style="margin-top: 30px; margin-bottom: 25px;">
            Fees and Payments – General
          </p>

          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice facilitates payments for the sharing of DJ fees to the creators
            of content played by the DJ in a live DJ performance setting. Each
            payment to Aslice is treated as a “Transaction” and is considered
            income to Aslice. Our service fee is calculated based upon the amount
            of the Transaction and deducted from such Transaction amount. The
            remaining balance is paid to producers and documented as an expense of
            Aslice.
          </p>
          <p class="section-title-purple">Fees and Payments - DJs</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            DJs may electively “share” earnings with the producers whose music
            they play via Aslice. This is effectively a purchase of a product or
            service from Aslice on behalf of the producer. No “download” will
            transpire, you are instead paying the producer for the service of
            their music when you play it.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            You understand and agree that Aslice is a platform that facilitates
            sharing between DJs who play music and the producers who make it - not
            a streaming service, or digital retailer. All DJ designated “shares”
            to third parties are final (except where and as prohibited by law),
            unless otherwise determined by Aslice, in our sole discretion. In the
            event you have an issue with amounts shared (a “purchase”), you must
            contact Aslice (by email) with a statement of the nature of your
            issue, any requests concerning the issue, and detailed information
            about the playlist in question. Aslice will determine what, if any,
            action will be taken (including the issuing of a credit or refund).
          </p>
          <p class="section-title-purple">
            Fees and Payments; Services – Producers.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            The price set for the service or playing your music is effectively
            chosen by the DJ when choosing the amount they wish to share (as a
            “purchase” in a “Transaction”).
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            You understand and agree that Aslice is entitled to a share of the
            revenue producers receive from Transactions (the “Revenue Share”),
            which will be calculated from the gross amount of each Transaction at
            a flat rate of 15%. Producers are solely responsible and liable for,
            and Company shall have no responsibility or liability for, any Payment
            Processor (such as Banks, Stripe, Transferwise, etc) fees (except for
            fees charged on Company’s account), bad debts (such as credit card
            returns or fraud), disputed payments, and refunds, except as provided
            in this Agreement.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice may withhold any taxes, duties, charges or levies on payments
            by Aslice to you pursuant to this Agreement as may be required by
            applicable law, rule or regulation. Aslice shall remit any such
            withheld taxes, duties, charges or levies to the appropriate tax
            authority.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice retains the right, but does not have the obligation, to
            immediately halt or modify our Services and the means in which we
            provide such services, prevent or restrict access to the Site or the
            Services or take any other action in case of technical problems,
            objectionable material, inaccurate listings, or actions otherwise
            prohibited by the procedures and guidelines contained on the Site, or
            for any other reason in the sole and absolute discretion of Company,
            and to correct any inaccurate listing or technical problems on the
            Site. Company may immediately halt the offering or sale of any goods
            or services upon receipt of notifications of a claimed infringement of
            intellectual property, upon acquiring knowledge of actual
            infringement, or becoming aware of facts or circumstances from which
            infringing material is apparent with respect to any goods or services.
          </p>
          <p class="section-title-purple">Additional Fees.</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            In addition to the fees set forth above, Company reserves the right to
            require payment of fees for certain additional features of the Service
            as yet to be imagined, including but not limited to “Pro” or
            “Management / agent” accounts. Should you elect to subscribe to such
            features, you shall pay all applicable fees, as described on the Site
            in connection with such features. Company reserves the right to change
            its price list and to institute new charges at any time, upon ten (10)
            days prior notice to you, which may be sent by e-mail or posted on the
            Site. Use of the Service by you following such notification
            constitutes your acceptance of any new or increased charges.
          </p>
          <p class="section-title-purple">Acknowledgement.</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            By registering with Aslice, you acknowledge your understanding and
            agreement to the terms spelled out in Aslice’s Terms page and to only
            share correct and un-falsified data; this includes (but is not limited
            to) your artist information, accurate playlists, and music metadata.
          </p>
          <p class="page-title" style="margin-top: 30px; margin-bottom: 25px;">
            General Terms
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            By using the Services, Aslice.com or any other websites, products,
            services and/or mobile applications owned or operating by Aslice
            (collectively referred to herein as the “Website”), you are agreeing
            to these Terms of Service (“Terms”) and the Aslice Privacy Policy
            (“Privacy Policy”) which we may update or amend from time to time as
            set forth herein. Any update or amendment to these Terms shall be
            effective if notice is provided to you. You agree that posting any
            such changes on the Services and/or Website and/or sending any such
            changes to you via email constitutes reasonable and sufficient notice.
            Any such amendment will be effective as of the date specified by
            Aslice. Your continued use of the Website (including any updates or
            other versions thereof) after we provide notice of any changes to
            these Terms constitutes your agreement to those changes. If you do not
            agree to these Terms (or any updates to or modified versions thereof),
            you should discontinue use of the Website immediately. You agree that
            these Terms are the legal equivalent of a written contract signed by
            you and constitutes a legally binding agreement between Aslice and
            you, as a user of the Services and/or Website, and that you have read
            these and understand these Terms and accept and will be bound by them.
          </p>
          <p class="gray-text red-link">
            PLEASE READ THE FOLLOWING TERMS AND CONDITIONS RELATING TO YOUR USE OF
            OUR WEBSITE AND OUR SERVICES CAREFULLY
          </p>
          <p class="section-title-purple">Accepting the Terms</p>
          <p class="gray-text">
            Before using or accessing the Services and/or Website, you must read
            this agreement and indicate your acceptance during the registration
            process. You may not use any of the Services or Website and you may
            not accept this agreement if you are not legally authorized to accept
            and be bound by these terms or are not at least 18 years of age and,
            in any event, of a legal age to form a binding contract with Aslice.
            <br />
            Scope of Agreement <br />
            By accessing and/or using the Services and/or Website, you agree to
            these Terms. These Terms apply to any use of the Services and/or
            Website including without limitation (1) when you register a user
            account; (2) when you sign up for, participate in or otherwise receive
            any product or services provided through the Website; and (3) when you
            use the Website as a guest. <br />
            Privacy and your Personal Information
            <br />
            You can view Aslice’s Privacy Policy here on the Website. By using the
            Services and/or Website, you also agree to be bound by the Privacy
            Policy and any changes thereto. You agree that Aslice may use and
            maintain your data according to the Privacy Policy. You give Aslice
            permission to combine information you enter or upload in connection
            with the Services and/or Website with that of other users of the
            Services and/or Website and/or other Aslice services. For example,
            this means that Aslice may use your and other users’ non-identifiable,
            aggregated data to improve the Services and/or Website or to design
            promotions. Aslice may access or store personal information in
            multiple countries, including countries outside of your own country,
            to the extent permitted by applicable law.
          </p>
          <p class="section-title-purple">Fan Personal Information – Fans.</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            As a condition to using certain features of the Service (including,
            without limitation, viewing playlists, following DJs or producers),
            you may be required to provide certain personal information to Company
            or relevant Artists, such as your e-mail address, country of residence
            and zip/postal code ("Fan Information"). <br />
            You may register with the Company as a fan and create a user account
            (“Fan Account”), which will allow you to access certain features of
            the Service that are only available through Fan Accounts, such as a
            fan profile page that lists your favorite producers and DJs along with
            information that you choose to share about yourself, and the ability
            to follow other fans, DJs or Artists.
          </p>
          <p class="gray-text">
            As a condition to each such Artist or DJ’s right to receive such Fan
            Information, Company requires each Artist or DJ to agree to the terms
            set forth in the next section of this Agreement. However, you
            acknowledge that Company has no control over the use of the Fan
            Information by the Artists, and you further acknowledge and agree that
            Aslice shall not be responsible or liable, directly or indirectly, for
            any damage or loss caused or alleged to be caused by or in connection
            with the Artists or DJs’ use of such Fan Information. <br />
            Fan Personal Information - Artists and DJs. <br />
            In connection with certain features of the Service, including by
            requiring that fans provide you with their e-mail addresses and other
            (limited) personal information when they follow your profile, you may
            collect certain types of Fan Information. As a condition to receiving
            such Fan Information, you acknowledge and agree that you will not
            disclose, rent, or sell any Fan Information to any third party, and
            you will use the Fan Information only in connection with the Artist or
            DJ’s mailing list or Subscription, in order to provide fans with
            general information and news about the Artist or DJ, such as upcoming
            shows, new Music or other Content delivered in connection with your
            profile. You further agree that any e-mail or other communication sent
            to the Artist’s mailing list or sent in connection with the Artist
            Subscription will comply with e-mail marketing laws and include a
            mechanism by which the recipient can unsubscribe from the mailing
            list, along with clear instructions on how to do so (i.e. a
            functioning "opt-out" mechanism). You will promptly implement a
            recipient’s request to opt-out of receiving e-mails from you and will
            not send any further e-mails to such opting-out recipient.
          </p>
          <p class="section-title-purple">Use of the Services and Website.</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Subject to the terms and conditions of this agreement, we hereby grant
            you a limited, revocable, non-transferable and non-exclusive license
            to access and use the Services and the Website by displaying the
            Website on your internet browser only for the purpose of understanding
            the Aslice products and services and not for purposes of resale or any
            other commercial use or use on behalf of any third party, except as
            explicitly permitted by us in advance. Any breach of this agreement
            may result in the immediate revocation of the license granted in this
            paragraph without notice to you. Except as permitted above, you may
            not reproduce, distribute, display, sell, lease, transmit, create
            derivative works from, translate, modify, reverse-engineer,
            disassemble, decompile or otherwise exploit the Services and/or
            Website or any portion of it unless expressly permitted by us in
            writing. You may not make any commercial use of any of the information
            provided by the Services or on the Website or make any use of the
            Services and/or the Website for the benefit of another business unless
            explicitly permitted by us in advance. We reserve the right to refuse
            service, terminate accounts, and/or cancel orders at our discretion,
            including, without limitation, if we believe that customer conduct
            violates this agreement, applicable law or is harmful to our
            interests. You agree not to upload, distribute, or publish any content
            or material through the Services or the Website that (a) violates or
            infringes the copyrights, patents, trademarks, service marks, trade
            secrets, or other proprietary rights of any person; (b) is libelous,
            threatening, defamatory, obscene, pornographic, or could give rise to
            any civil or criminal liability under U.S. or international law; or
            (c) includes any bugs, viruses, or other harmful code or properties.
            Submissions or opinions expressed by or through the Services or on the
            Website are that of the individual expressing such submission or
            opinion and may not reflect our opinions. We may assign you a password
            and account identification to enable you to access and use the
            Services and certain portions of the Website. Each time you use a
            password or identification, you will be deemed to be authorized to
            access and use the Services and/or Website in a manner consistent with
            the terms and conditions of this agreement, and we have no obligation
            to investigate the authorization or source of any such access or use
            of the Services and/or Website. You will be responsible for all access
            to and use of the Services and the Website by anyone using your
            password and login name, including all communications and obligations
            incurred, whether or not such access was actually approved by you. You
            are responsible for protecting the security and confidentiality of the
            password and identification assigned to you. Please notify us
            immediately of any unauthorized use of your password or identification
            or any other breach or threatened breach of the Services’ and/or
            Website’s.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Your access and use of the Services and/or Website may be interrupted
            from time to time for any of several reasons, including, without
            limitation, the malfunction of equipment, periodic updating,
            maintenance, or repair of the Services and/or Website or other actions
            that Aslice, in its sole discretion, may elect to take. Aslice shall
            not be liable to any party or any user for any loss, cost, or damage
            that results from any scheduled or unscheduled downtime, regardless of
            the cause. At our sole discretion and without prior notice or
            liability, we may discontinue, modify or alter any aspect of the
            Services and/or Website, including but not limited to (1) restricting
            the time the Services and/or the Website is available; (2) restricting
            the amount of use permitted; (3) restricting or terminating any user’s
            right to use the Services and/or Website.
          </p>
          <p class="gray-text">
            We have the right to refuse or cancel orders or memberships if a
            product or service description or information is listed on the Website
            with a typographical error that incorrectly identifies the true price
            or service conditions of a product or service. We have the right to
            refuse or cancel any such orders whether or not the order has been
            confirmed and your Payment Method charged. If your Payment Method has
            already been charged for the purchase and your order is canceled, we
            will issue a credit to your payment account in the amount of the
            charge and you agree that a credit is your sole remedy.
          </p>
          <p class="section-title-purple">
            Registration Information, Security, and Electronic Communications
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            You may browse the Site and view some Content without registering, but
            as a condition to using certain aspects of the Service, you may be
            required to register with Company and select a password and screen
            name. You must register for an account with us and use the Website
            using your true identity (your “User Account”). As part of such
            registration, we may verify your identity. You authorize us to make
            any inquiries we consider necessary to validate your identity. These
            inquiries may include asking you for further information, requiring
            you to provide your full address, your social security number and/or
            requiring you to take steps to confirm ownership of your email address
            or financial instruments and accounts or verifying information you
            provide against third party databases or through other sources. If you
            do not provide this information or Aslice cannot verify your identity,
            we can refuse to allow you to use the Services and/or the Website.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            You agree that you will not share your login information, password, or
            other account information with anyone else. You understand and agree
            to take all necessary steps to protect your User Account information
            as detailed in this agreement. We will never ask for your password.
            You are solely responsible for all acts and omissions made using your
            login information and password. You agree that we carry no liability
            for losses or problems that may arise due to your sharing this private
            information with other people, businesses, or entities. You must
            notify us immediately in the event of any known or suspected
            unauthorized use of your User Account, or any known or suspected
            breach of security, including loss, theft, or unauthorized disclosure
            of your or anyone else’s password or User Account Information (as
            defined below).
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            By providing us with your email address, you consent to receive all
            required notices and information electronically. Electronic
            communications may be posted on the Website and/or delivered to your
            email address that we have on file for you. It is your responsibility
            to promptly update us with your complete, accurate contact
            information, or change your information, including email address, as
            appropriate. Notices will be provided in HTML (or, if your system does
            not support HTML, in plain-text) in the text of the e-mail or through
            a link to the appropriate page on our Website, accessible through any
            standard, commercially available internet browser. Your consent to
            receive communications electronically is valid until you end your
            relationship with us.
          </p>
          <p class="gray-text">
            You may print a copy of any electronic communications and retain it
            for your records. We reserve the right to terminate or change how we
            provide electronic communications and will provide you with
            appropriate notice in accordance with applicable law. If you become
            aware of any unauthorized use of your Registration or Account
            Information for the Website, you agree to notify Aslice immediately
            via email to support@Aslice.com.
          </p>
          <p class="section-title-purple">
            User Responsibilities
          </p>
          <p class="gray-text">
            For purposes of your use of the Services and the Website (including
            registration, identification and billing) you agree to provide true,
            accurate and complete information as required by the sign-up process
            to our Website, including without limitation your legal name, address,
            telephone number, email address and applicable billing information
            (collectively “User Account Information”). You expressly allow us to
            share your User Account Information with third parties for the purpose
            of verifying the information you provide and charging your account.
            Your User Account Information is considered Personal Information and
            shall be treated as such pursuant to our Privacy Policy. <br />
            You will maintain and promptly update your User Account Information to
            keep it accurate. If you provide any information that is untrue,
            inaccurate, or incomplete, or we have grounds to suspect that
            information you provided is untrue, inaccurate, or incomplete (in our
            sole discretion), we reserve the right to suspend or terminate your
            User Account and refuse any and all current or future use by you of
            our Services and/or Website. You also agree not to register for more
            than one username, create an account on behalf of someone else, or
            create a false or misleading identity on the Website. If you User
            Account is revoked for any reason you will not register with our
            Website using another username or through any other means, and we
            reserve the right to terminate any account of any user who we have
            reason to believe was previously terminated without notice, and to
            exercise any other rights or remedies available to us at law or in
            equity.
          </p>
          <p class="section-title-purple">
            Mobile Services
          </p>
          <p class="gray-text">
            Services may be available via mobile devices, including (a) the
            ability to upload content to the Website via mobile device; (b) the
            ability to browse the Website from a mobile device; or (c) the ability
            to access certain features through an application downloaded and
            installed on a mobile device. To the extent you utilize a mobile
            device, your wireless service carrier’s standard charges, data rates,
            and other fees may apply. Additionally, downloading, installing, or
            using the Services and/or Website may be prohibited or restricted by
            your carrier, and not all carriers or devices may support the Services
            and/or Website. By using a mobile device, you agree that we may
            communicate with you via SMS, MMS, text message, or other electronic
            means to your mobile device and that certain information about your
            usage of your mobile device may be communicated to us. We will comply
            with any requirements under applicable laws and regulations before
            communicating with you in this manner. In the event you change or
            deactivate your mobile telephone number, you agree to promptly update
            your account information to ensure that your messages are not sent to
            the person who acquires your old number. <br />
            Third-Party Payment Processing <br />
            We accept credit/debit card and/or ACH payments only. You agree to pay
            all fees charged to your account based on the charges and billing term
            in effect as shown in the Terms of Use. All payments shall be made by
            credit card, debit card, ACH, or other valid form of payment accepted
            by Aslice (“Payment Method”) at the start of the month of use. You can
            update your Payment Method information on the Website by logging in to
            your account. We may also update your Payment Method information using
            information provided by the card networks or other payment service
            providers. For example, if your card number changes as a result of
            expiration or a lost or stolen card, we may continue to charge any
            replacement card number associated with your payment account.
            Following any update, you authorize us to continue to charge the
            applicable payment account(s). You remain responsible for any
            uncollected amounts. If your Payment Method cannot be charged for any
            reason, Aslice reserves the right to attempt to charge your preferred
            Payment Method again, charge any other valid Payment Method associated
            with your account, suspend further service until you provide a valid
            Payment Method, or terminate your Membership. All payments will be in
            US Dollars.
          </p>
          <p class="section-title-purple">Alerts</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice may from time to time provide voluntary or automatic alerts and
            voluntary account-related alerts (“Alerts”). Automatic Alerts may be
            sent to you following certain changes to your account or information,
            such as a change in your Registration Information. Voluntary Alerts
            may be turned on by default as part of the Services and/or Website.
            They may then be customized, deactivated, or reactivated by you. These
            Alerts allow you to choose Alert messages for your accounts. Aslice
            may add new Alerts from time to time, or cease to provide certain
            Alerts at any time, in its sole discretion. Each Alert has different
            options available, and you may be asked to select from among these
            options upon activation of your Alerts service. You understand and
            agree that any Alerts provided to you through the Services and/or
            Website may be delayed or prevented by a variety of factors. Aslice
            may make commercially reasonable efforts to provide Alerts in a timely
            manner with accurate information, but cannot guarantee the delivery,
            timeliness, or accuracy of the content of any Alert. Aslice shall not
            be liable for any delays, failure to deliver, or misdirected delivery
            of any Alert; for any errors in the content of an Alert; or for any
            actions taken or not taken by you or any third party in reliance on an
            Alert.
          </p>
          <p class="gray-text">
            Electronic Alerts will be sent to the email address you have provided
            as your primary email address for the Website. If your email address
            or your mobile device’s email address changes, you are responsible for
            informing us of that change. You can also choose to have Alerts sent
            to a mobile device that accepts text messages. Changes to your email
            address or mobile number will apply to all of your Alerts. <br />
            Because Alerts are not encrypted, we will never include your private
            or personal banking information or your password or passcode. However,
            Alerts may include your Login ID and some information about your
            accounts. Depending upon which Alerts you select, information such as
            an account balance or the due date for your credit card payment may be
            included. Anyone with access to your email will be able to view the
            content of these Alerts. At any time, you may disable featured Alerts.
          </p>
          <p class="section-title-purple">
            Aslice’s Intellectual Property Rights
          </p>
          <p class="gray-text">
            The Services and the contents of the Website, including all
            functionality and “look and feel” (e.g., text, graphics, images, logos
            and button icons), photographs, editorial content, notices, software
            (including html-based computer programs), coding and any other
            materials provided by us to you are protected under both United States
            and other applicable copyright, trademark, and other laws. The
            Services and contents of the Website belong to or are licensed to
            Aslice or its software or content suppliers. Aslice grants you the
            right to use the Services and view and use the Website subject to
            these terms. You may download or print a copy of information related
            to or generated by the Website for your personal, internal, and
            non-commercial use only. Any distribution, reprint, or electronic
            reproduction of any content from the Website in whole or in part for
            any other purpose is expressly prohibited without our prior written
            consent. You agree not to use, nor permit any third party to use, the
            Services, the Website or any content in a manner that violates any
            applicable law, regulation or this agreement.
          </p>
          <p class="section-title-purple">
            Your Intellectual Property Rights
          </p>
          <p class="gray-text">
            In order to allow us to provide the Services and Website, we must
            obtain certain license and other rights to content and information
            that you provide to us. This is necessary so that we can process,
            maintain, store, reproduce, back-up, distribute, or otherwise handle
            content which is uploaded or submitted by you. By using the Services
            and using or accessing the Website and agreeing to these Terms, except
            as otherwise provided elsewhere in this agreement or on the Website,
            you agree that we have the right to use anything that you submit or
            post to the Website and/or provide us, including without limitation,
            ideas, questions, reviews, comments, and suggestions (collectively,
            "Submissions"). It is acknowledged and understood that,
            notwithstanding anything herein to the contrary, Submissions do not
            include any actual song(s) or artistic performance, but do include
            song titles, the name of the performer(s), genre, label/producer name,
            user uploaded related likenesses/photos/depictions and related artwork
            and information. You hereby grant us the royalty-free, worldwide,
            perpetual, irrevocable, sublicensable and transferable right to use,
            copy, distribute, display, publish, perform, sell, lease, transmit,
            adapt, translate, modify, reverse-engineer, disassemble, or create
            derivative works from such Submissions by any means and in any form.
            In addition to the rights applicable to any Submission, when you post
            comments or reviews to the Website, you also hereby grant us the right
            to use the name that you submit with any review, comment, or other
            content in connection with such review, comment, or content. You
            represent and warrant that you own or otherwise control all of the
            rights to the reviews, comments and other content that you post on the
            Website and that use of your reviews, comments, or other content by us
            will not infringe upon or violate the rights of any third party. You
            agree not to use a false email address, pretend to be someone other
            than yourself, or mislead us or any third parties regarding the origin
            of any Submissions or content. We have the right to remove or edit any
            Submissions for any reason.
          </p>
          <p class="section-title-purple">
            User Conduct and Content
          </p>
          <p class="gray-text">
            You are solely responsible for all descriptions, comments, statements,
            videos, images, information, data, text, software, music, sound,
            photographs, graphics, messages, or other materials (“content”) that
            you upload, post, publish, display, or otherwise make available
            (hereinafter “upload”) to us. The following are examples of content
            and/or use that is prohibited by Aslice. This is not an exclusive or
            exhaustive list, however, and we reserve the right to investigate
            anyone by engaging public or private organizations, including but not
            limited to collection agents, private investigators, and local, state,
            federal, or other applicable agencies, and to take any action against
            anyone who, in our sole discretion, violates any of the terms or the
            spirit of this agreement. These actions include but are not limited to
            suspending or terminating the account of violators and reporting you
            to law enforcement authorities or otherwise taking any other
            appropriate legal action. Without limiting the foregoing, you agree to
            NOT use the Services or Website to: <br />
            Engage in any activity that violates any law or governmental rule or
            regulation or which is otherwise illegal under the laws of any state,
            federal, or local government or agency; Transmit or otherwise upload
            any content that (a) infringes any intellectual property or other
            proprietary rights of any party; (b) you do not have a right to upload
            under any law or under contractual or fiduciary relationship; (c)
            contains any file which contains viruses, worms, Trojan horses, or any
            other contaminating or destructive features, or that otherwise
            interferes or is intended to interfere with the proper working of the
            Website; (d) poses or creates a privacy or security risk to any
            person; or (e) in the sole judgment of Aslice is objectionable or
            which restricts or inhibits any other person from using or enjoying
            the Website, or which may expose us or our users to any harm or
            liability of any type; or <br />
            Use any robot, spider, scraper, deep-link or other similar automated
            data gathering or extraction tools, program, algorithm, or other
            mechanism or methodology to access, acquire, copy, or monitor the
            Website or information collected by Aslice or any portion of the
            Website, without Aslice’s express written consent, which may be
            withheld in Aslice’s sole discretion; or Use or attempt to use any
            engine, software, tool, agent, or other device or mechanism (including
            without limitation browsers, spiders, robots, avatars, or intelligent
            agents) to navigate or search the services, other than the search
            engines and search agents available through the Services or generally
            available third-party web browsers (such as Microsoft Explorer); or
            Attempt to decipher, decompile, disassemble, or reverse-engineer any
            of the software comprising or in any way making up any part of the
            Website; or <br />
            Attempt to gain unauthorized access to any portion of the Website or
            any unauthorized information.
          </p>
          <p class="section-title-purple">
            Third-Party Content
          </p>
          <p class="gray-text">
            Under no circumstances will Aslice be liable in any way for any
            content or materials of any third parties (including users,)
            including, but not limited to, for any errors or omissions in any
            content, or for any loss or damage of any kind incurred as a result of
            the use of any such content. You acknowledge that we do not pre-screen
            content, but that we and our designees will have the right, but not
            the obligation, in our sole discretion to refuse or remove any content
            for any reason that is used with the Services and/or uploaded to the
            Website. <br />
            Disclaimer of Representations and Warranties <br />
            The website, services, information, data, features, and all content
            and all services and products associated with the services or provided
            through the services (whether or not sponsored) are provided to you on
            an “as-is” and “as available” basis. Aslice, its subsidiaries,
            associates, and affiliates, and its third-party providers, licensors,
            distributors, or suppliers (collectively, "suppliers") make no
            representations or warranties of any kind, express or implied, as to
            the content or operation of the website or of the services. you
            expressly agree that your use of the services is at your sole risk.
            <br />
            Neither Aslice nor its suppliers make any representations, warranties
            or guarantees, express or implied, regarding the accuracy,
            reliability, or completeness of the content on the website or of the
            services (whether or not sponsored), and expressly disclaims any
            warranties of non-infringement or fitness for a particular use or
            purpose. Neither Aslice or its suppliers make any representation,
            warranty or guarantee that the content that may be available through
            the services is free of infection from any viruses or other code or
            computer programming routines that contain contaminating or
            destructive properties or that are intended to damage, surreptitiously
            intercept, or expropriate any system, data, or personal information.
            Some jurisdictions do not allow the exclusion of certain warranties or
            the limitation or exclusion of liability for incidental or
            consequential damages. In such states liability is limited to the
            extent permitted by law. accordingly, some of the above limitations of
            sections of this provision or found elsewhere in this agreement may
            not apply to you.
          </p>
          <p class="section-title-purple">
            Limitations on Aslice’s Liability
          </p>
          <p class="gray-text">
            To the fullest extent of the law, you agree that Aslice, its
            affiliates, agents and their respective officers, directors,
            employees, and agents will not be liable for any personal injury or
            for any indirect, consequential or special damages. The cumulative
            liability of Aslice to you for all claims arising from or relating to
            this agreement, or your use of the website, including, without
            limitation, any cause of action sounding in contract, tort, or strict
            liability, will not exceed the greater of (a) the total amount paid to
            Aslice by you during the one-month period prior to the act, omission
            or occurrence giving rise to such liability or (b) the remedy or
            penalty imposed by the statute under which such claim arises. this
            limitation of liability is intended to apply without regard to whether
            other provisions of this agreement have been breached or have proven
            ineffective or if a remedy fails of its essential purpose. Some
            jurisdictions do not allow for limited liability, so not all of the
            above limitations may apply to you. The foregoing limitation of
            liability shall not extend to claims for damages for personal injury
            or property damage by users who reside in the state of new jersey or
            to claims by new jersey residents for any damages caused by Aslice
            fraud, deception, false promise, misrepresentation, omission of any
            material fact or intentional or reckless misconduct.
          </p>
          <p class="section-title-purple">
            Your Indemnification of Aslice
          </p>
          <p class="gray-text">
            You agree to defend, indemnify and hold harmless Aslice and its
            officers, directors, members, owners, affiliates and employees, from
            and against all claims, suits, proceedings, losses, liabilities, and
            expenses, whether in tort, contract, or otherwise, that arise out of
            or in any way relate to your use of the Services and/or Website or any
            breach of this agreement, including but not limited to reasonable
            costs and attorney’s fees.
          </p>
          <p class="section-title-purple">
            External Links
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            The Website may contain links to other sites on the Internet that are
            owned and operated by third parties. You acknowledge that we are not
            responsible for the operation of or content located on or through any
            such site. <br />
            Governing Law; Venue; Alternative Dispute Resolution Agreement (“ADR
            Agreement”)<br />
            The terms of this paragraph shall constitute the ADR Agreement. All
            matters relating to your access or use of the Services and Website,
            specifically including all disputes, will be governed by the laws of
            the United States and the State of Minnesota, without regard to its
            conflict of law provisions. You agree to the personal jurisdiction by
            and venue in the state and federal courts in Hennepin County,
            Minnesota, and waive any objection to such jurisdiction or venue. Any
            claim under these Terms of Service must be brought within one (1) year
            after the cause of action arises, or such claim or cause of action is
            barred. Claims made under the separate terms and conditions of the
            purchase for goods and services are not subject to this limitation. No
            recovery may be sought or received for damages other than
            out-of-pocket expenses, except that the prevailing party will be
            entitled to costs and attorneys’ fees. In the event of any controversy
            or dispute between Aslice and you arising out of or in connection with
            your use of the Services and/or Website, the parties shall attempt,
            promptly and in good faith, to resolve such dispute. If we are unable
            to resolve any such dispute within a reasonable time (not to exceed
            one hundred eighty (180) days), then either party may submit such
            controversy or dispute to mediation venued in Hennepin County,
            Minnesota. If the dispute cannot be resolved through mediation, then
            the parties shall be free to pursue any right or remedy available to
            them under Minnesota law. To the extent the parties are permitted
            under this ADR Agreement to initiate litigation in a court, both you
            and Aslice agree that all claims and disputes arising out of or
            relating to the agreement will be litigated exclusively in either a
            small claims court of competent jurisdiction or the state or federal
            courts located in Hennepin County, Minnesota.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Waiver of Jury Trial. You and Aslice hereby waive any constitutional
            and statutory rights to have a trial in front of a judge or a jury.
            You and Aslice are electing that all claims and disputes shall be
            first referred to mediation in Hennepin County pursuant to this ADR
            Agreement, except as specified herein.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Waiver of Class or Consolidated Actions. You and Aslice agree to waive
            any right to resolve claims within the scope of this adr agreement on
            a class, collective, or representative basis. all claims and disputes
            within the scope of this adr agreement must be arbitrated on an
            individual basis and not on a class or representative basis. Only
            individual relief is available, and claims of more than one customer
            or user cannot be arbitrated or consolidated with those of any other
            customer or user. In the event that this subparagraph is deemed
            invalid or unenforceable neither you nor we are entitled to
            arbitration and instead claims and disputes shall be resolved in a
            court as set forth herein.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            30-Day Right to Opt Out. You have the right to opt out of the
            provisions of this ADR Agreement by sending written notice of your
            decision to opt out to the following address: Aslice, Attn: Aslice
            Account Sevices, within 30 days after first becoming subject to this
            ADR Agreement. Your notice must include your name and address, your
            Aslice username (if any), the email address you used to set up your
            Aslice account (if you have one), and an unequivocal statement that
            you want to opt out of this ADR Agreement. If you opt out of this ADR
            Agreement, all other parts of this agreement will continue to apply to
            you. Opting out of this ADR Agreement has no effect on any other ADR
            Agreements that you may currently have, or may enter in the future,
            with us.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Severability. If any part or parts of this ADR Agreement are found
            under the law to be invalid or unenforceable, then such specific part
            or parts shall be of no force and effect and shall be severed and the
            remainder of the ADR Agreement shall continue in full force and
            effect.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Survival of Agreement. This ADR Agreement will survive the termination
            of your relationship with Aslice.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Modification. Notwithstanding any provision in this agreement to the
            contrary, we agree that if Aslice makes any future material change to
            this ADR Agreement, it will not apply to any individual claim(s) that
            you had already provided notice of to Aslice.
          </p>
          <p class="gray-text">User Disputes</p>
          <p class="gray-text">
            You understand and acknowledge that you are solely responsible for
            your interactions with any other user in connection with the Services
            and Website, and Aslice will have no liability or responsibility with
            respect thereto. We reserve the right, but have no obligation, to
            become involved in any way with disputes between you and any other
            user of the Services and Website.
          </p>
          <p class="section-title-purple">
            General
          </p>
          <p class="gray-text">
            These Terms of Service and the other agreements referenced herein
            constitute the entire agreement between you and Aslice and govern your
            use of the Services and Website, superseding any prior agreements
            between you and us with respect to the Services and/or the Website.
            You may also be subject to additional terms and conditions that may
            apply when you use affiliate or third-party services, third-party
            content, or third-party software. Our failure to exercise or enforce
            any right or provision of these Terms of Service will not constitute a
            waiver of such right or provision. If any provision of these Terms of
            Service is found by a court of competent jurisdiction to be, or are
            otherwise, invalid or unenforceable, the parties agree that the court
            should endeavor to give effect to the parties’ intentions as reflected
            in the provision, and the other provisions of this agreement shall
            remain in full force and effect. Notices to you may be made via email
            or regular mail, and these Terms of Service may be amended or updated
            by us at any time, for any reason, with or without notice to you.
          </p>
        </div>
      </div>
    </div>
    <Footer> </Footer>
  </div>
</template>

<script>
import Footer from "@/components/FooterNew";
export default {
  metaInfo: {
    title: "Terms of Use",
    meta: [
      {
        property: "og:description",
        content:
          "Welcome to our Terms Of Use page. Information for Artists, Music Producers, DJs, Fans, Followers and more. General Terms. Use of the Services and Website. Registration Information, Security, and Electronic Communications…"
      },
      {
        name: "description",
        content:
          "Welcome to our Terms Of Use page. Information for Artists, Music Producers, DJs, Fans, Followers and more. General Terms. Use of the Services and Website. Registration Information, Security, and Electronic Communications…"
      }
    ]
  },
  components: {
    Footer
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>

<style scoped>
.container-fluid {
  padding: 3.5px 21.5px;
  padding-top: 150px;
  min-height: calc(100vh - 365px);
  background: #e5e5e5;
}
</style>
